import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Section,
  SectionWrapper,
  ImagesContainer,
  ImageLeft,
  ImageTitle,
  RightSide,
  ImageRight,
  ImageRow,
  ImageRightSmall,
  NewsContainer,
  NewContainer,
  NewPhoto,
  NewColumn,
  Description,
  DateText,
  NewsTitle,
  SortContainer,
  StyledImageTitle,
  Buttons,
  Input,
  InputContainer,
  SearchIcon,
  Text,
} from "./NewsPage.styles";

import { formatDate, newsData } from "../../utils/utils";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Button from "../../components/utils/Button/Button";

import { ReactComponent as ByDate } from "../../assets/icons/byDate.svg";
import { ReactComponent as ByTitle } from "../../assets/icons/byAlphabet.svg";

const NewsPage = () => {
  const navigate = useNavigate();
  const [visibleCount, setVisibleCount] = useState(7);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [loading]);

  const loadMoreNews = () => {
    setLoading(true);
    setTimeout(() => {
      setVisibleCount((prevCount) => prevCount + 7);
    }, 500);
  };

  /* SCROLL ANIMATION */

  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  const handleIntersection = (setIntersecting, ref) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin: "-300px" }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  };

  useEffect(() => handleIntersection(setIsIntersecting, ref, "slide-in"), []);

  useEffect(() => {
    const handleClassToggle = (isIntersecting, ref, className) => {
      ref.current.querySelectorAll("div").forEach((el) => {
        if (isIntersecting) {
          el.classList.add(className);
        }
      });
    };

    handleClassToggle(isIntersecting, ref, "slide-in");
  }, [isIntersecting]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [sortedNews, setSortedNews] = useState(newsData);
  const [activeSort, setActiveSort] = useState("");
  const [isDateAscending, setIsDateAscending] = useState(true);
  const [search, setSearch] = useState("");
  const [searchOpen, setSearchOpen] = useState(false);

  // Toggle sort by date (asc or desc)
  const sortByDate = () => {
    setActiveSort("byDate");
    const sorted = [...sortedNews].sort((a, b) => {
      return isDateAscending
        ? new Date(a.date) - new Date(b.date) // Ascending (oldest to newest)
        : new Date(b.date) - new Date(a.date); // Descending (newest to oldest)
    });
    setIsDateAscending(!isDateAscending); // Toggle sorting order
    setSortedNews(sorted);
  };

  // Sort by title alphabetically (A-Z)
  const sortByTitle = () => {
    setActiveSort("byTitle");
    const sorted = [...sortedNews].sort((a, b) =>
      a.title.localeCompare(b.title)
    );
    setSortedNews(sorted);
  };

  // Filter news by search query
  const filteredNews = sortedNews.filter((news) =>
    news.title.toLowerCase().includes(search.toLowerCase())
  );

  const sortByDateStyles = {
    backgroundColor: activeSort === "byDate" ? "#fff" : "#FE5F55",
    border: activeSort === "byDate" ? "1px solid #fe5f55" : "1px solid #fff",
    padding: 0,
    boxShadow:
      activeSort === "byDate" ? "none" : "0px 8px 12px 0px rgba(0, 0, 0, 0.1)",
    minWidth: 48,
    minHeight: 32,
    paddingTop: 5,
  };

  const sortByTitleStyles = {
    boxShadow:
      activeSort === "byTitle" ? "none" : "0px 8px 12px 0px rgba(0, 0, 0, 0.1)",
    border: activeSort === "byTitle" ? "1px solid #fe5f55" : "1px solid #fff",
    backgroundColor: activeSort === "byTitle" ? "#fff" : "#FE5F55",
    padding: 0,
    minWidth: 48,
    minHeight: 32,
    paddingTop: 5,
  };

  return (
    <Container>
      <Header />
      <Section $firstSection ref={ref}>
        <SectionWrapper>
          <ImagesContainer>
            <ImageLeft
              $bgImage={newsData[3].thumbnail}
              onClick={() => navigate("/news-details", { state: newsData[3] })}
            >
              <StyledImageTitle>{newsData[3].title}</StyledImageTitle>
              <ImageTitle>{newsData[3].title}</ImageTitle>
            </ImageLeft>

            <RightSide>
              <ImageRight
                $bgImage={newsData[2].thumbnail}
                onClick={() =>
                  navigate("/news-details", { state: newsData[2] })
                }
              >
                <StyledImageTitle>{newsData[2].title}</StyledImageTitle>
                <ImageTitle>{newsData[2].title}</ImageTitle>
              </ImageRight>
              <ImageRow>
                <ImageRightSmall
                  $bgImage={newsData[5].thumbnail}
                  onClick={() =>
                    navigate("/news-details", { state: newsData[5] })
                  }
                >
                  <StyledImageTitle $small>
                    {newsData[5].title}
                  </StyledImageTitle>

                  <ImageTitle $small>{newsData[5].title}</ImageTitle>
                </ImageRightSmall>
                <ImageRightSmall
                  $bgImage={newsData[6].thumbnail}
                  onClick={() =>
                    navigate("/news-details", { state: newsData[6] })
                  }
                >
                  <StyledImageTitle $small>
                    {newsData[6].title}
                  </StyledImageTitle>

                  <ImageTitle $small>{newsData[6].title}</ImageTitle>
                </ImageRightSmall>
              </ImageRow>
            </RightSide>
          </ImagesContainer>

          <NewsContainer ref={ref}>
            <SortContainer>
              <InputContainer $open={searchOpen}>
                <SearchIcon
                  onClick={() => {
                    if (searchOpen) {
                      setSearch("");
                    }
                    setSearchOpen(!searchOpen);
                  }}
                  $open={searchOpen}
                />
                <Input
                  $open={searchOpen}
                  disabled={!searchOpen}
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </InputContainer>
              <Buttons>
                <Button
                  onClick={sortByDate}
                  containerStyle={sortByDateStyles}
                  title={
                    <ByDate
                      color={activeSort === "byDate" ? "#fe5f55" : "#fff"}
                    />
                  }
                />
                <Button
                  $active
                  onClick={sortByTitle}
                  title={
                    <ByTitle
                      color={activeSort === "byTitle" ? "#fe5f55" : "#fff"}
                    />
                  }
                  containerStyle={sortByTitleStyles}
                />
              </Buttons>
            </SortContainer>
            {filteredNews.slice(0, visibleCount).map((data) => (
              <NewContainer key={data.id}>
                <NewPhoto src={data.thumbnail} />
                <NewColumn>
                  <DateText>{formatDate(data.date)}</DateText>
                  <NewsTitle>{data.title}</NewsTitle>
                  <Description>{data.description}</Description>

                  <Button
                    title={"Read More"}
                    onClick={() => navigate("/news-details", { state: data })}
                  />
                </NewColumn>
              </NewContainer>
            ))}
            {filteredNews.length === 0 && (
              <Text>
                No results found. Please try searching with a different title.
              </Text>
            )}
            {visibleCount < filteredNews.length &&
              (loading ? (
                <Button
                  title={
                    <l-waveform
                      size="18"
                      stroke="2.5"
                      speed="1"
                      color="#FE5F55"
                    />
                  }
                  containerStyle={{
                    alignSelf: "center",
                    backgroundColor: "white",
                    boxShadow: "none",
                    cursor: "default",
                  }}
                />
              ) : (
                <Button
                  title={"Click for more"}
                  containerStyle={{ alignSelf: "center" }}
                  onClick={loadMoreNews}
                  disabled={loading}
                  loading={loading}
                />
              ))}
          </NewsContainer>
        </SectionWrapper>
      </Section>

      <Footer />
    </Container>
  );
};

export default NewsPage;
