import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Photo = styled.img`
  width: 29.2rem;
  height: 39.8rem;
  object-fit: contain;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 1.2rem;
  @media (max-width: 829px) {
    display: ${(props) => (props.$hide ? "none" : "flex")};
  }
`;

const ImagePreloader = ({
  image,
  placeholderImage,
  photoStyles,
  alt,
  hide,
}) => {
  const [imageSrc, setImageSrc] = useState(placeholderImage);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      setImageSrc(image);
      setIsLoading(false);
    };
  }, []);

  return (
    <Photo
      $hide={hide}
      style={photoStyles}
      src={imageSrc}
      alt={alt}
      className={isLoading ? "loading" : ""}
    />
  );
};

export default ImagePreloader;
