import React, { useEffect, useRef, useState } from "react";
import { isValidPhoneNumber } from "libphonenumber-js";
import {
  Container,
  Section,
  SectionWrapper,
  Title,
  LogoIcon,
  InputContainer,
  Input,
  Label,
  TextArea,
  Form,
  StyledPhoneInput,
} from "./SupportPage.styles";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Button from "../../components/utils/Button/Button";
import openToast from "../../utils/toast";

const SupportPage = () => {
  const toastId = useRef(null);

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [description, setDescription] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});

  const handleOnChange = (value, setValue) => {
    setValue(value);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!fullName) newErrors.fullName = "First name and Last name is required";
    if (!description) newErrors.description = "Description is required";
    if (!phoneNumber) {
      newErrors.phoneNumber = "Mobile phone number is required";
    }
    if (!isValidPhoneNumber(`+${phoneNumber}`)) {
      newErrors.phoneNumber = "Invalid phone number";
      openToast(
        toastId,
        "invalid-phone-number",
        "error",
        "Invalid phone number"
      );
    }
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email format is invalid";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const resetValues = () => {
    setFullName("");
    setEmail("");
    setDescription("");
    setPhoneNumber("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      document.getElementById("supportForm").submit();
      openToast(
        toastId,
        "submit-message",
        "success",
        `Thank you, ${fullName}! Your support request has been successfully submitted.`
      );
      resetValues();
    }
  };

  /* SCROLL ANIMATION */

  const [isIntersecting, setIsIntersecting] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleIntersection = (setIntersecting, ref) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin: "-300px" }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  };

  useEffect(() => handleIntersection(setIsIntersecting, ref, "slide-in"), []);

  useEffect(() => {
    const handleClassToggle = (isIntersecting, ref, className) => {
      ref.current.querySelectorAll("div").forEach((el) => {
        if (isIntersecting) {
          el.classList.add(className);
        }
      });
    };

    handleClassToggle(isIntersecting, ref, "slide-in");
  }, [isIntersecting]);

  /* STILOVI */

  const PhoneButtonStyles = {
    background: "white",
    border: errors.phoneNumber ? "1px solid red" : "1px solid #8e8e8e",
    fontFamily: "Poppins-Medium",
    height: 36,
  };
  const PhoneInputStyles = {
    border: errors.phoneNumber ? "1px solid red" : "1px solid #8e8e8e",
    fontFamily: "Poppins-Medium",
    width: "100%",
    height: 36,
  };
  const PhoneDropdownStyles = {
    border: "1px solid #8e8e8e",
    fontFamily: "Poppins-Medium",
    borderRadius: "0.4rem",
    boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.05)",
  };
  const PhoneSearchStyles = {
    width: "calc(100% - 24px)",
    background: "white",
    zIndex: 2000,
  };

  return (
    <Container>
      <Header />

      <Section $firstSection ref={ref}>
        <SectionWrapper>
          <LogoIcon />
          <Title>Submit Your Support Request</Title>

          <Form
            id="supportForm"
            action="https://hooks.zapier.com/hooks/catch/11013572/21sozca/"
            method="POST"
            target="hidden_iframe"
          >
            <InputContainer>
              <Label>First and Last Name:</Label>
              <Input
                name="fullName"
                placeholder="Enter your first name and last name"
                $error={errors.fullName}
                value={fullName}
                onChange={(e) => handleOnChange(e.target.value, setFullName)}
              />
            </InputContainer>
            <InputContainer>
              <Label>Email:</Label>
              <Input
                name="email"
                placeholder="Enter your email address"
                $error={errors.email}
                value={email}
                type="email"
                onChange={(e) => handleOnChange(e.target.value, setEmail)}
              />
            </InputContainer>
            <InputContainer style={{ zIndex: 2000 }}>
              <Label>
                Mobile Phone Number (associated with your EyCrowd app):
              </Label>
              <StyledPhoneInput
                enableSearch
                autoFormat={false}
                disableSearchIcon
                countryCodeEditable
                placeholder="Enter your mobile phone number"
                searchPlaceholder="Search..."
                country={"us"}
                name="phoneNumber"
                value={phoneNumber}
                onChange={(e) => handleOnChange(e, setPhoneNumber)}
                buttonStyle={PhoneButtonStyles}
                inputStyle={PhoneInputStyles}
                dropdownStyle={PhoneDropdownStyles}
                searchStyle={PhoneSearchStyles}
              />
            </InputContainer>
            <InputContainer>
              <Label>Description:</Label>
              <TextArea
                name="description"
                placeholder="Please provide a complete description of your support request"
                $error={errors.description}
                value={description}
                onChange={(e) => handleOnChange(e.target.value, setDescription)}
              />
            </InputContainer>
            <Button
              title={"Submit"}
              onClick={handleSubmit}
              containerStyle={{
                width: "80%",
                alignSelf: "center",
                marginTop: 40,
                maxWidth: "25rem",
              }}
            />
          </Form>

          <iframe
            name="hidden_iframe"
            style={{ display: "none" }}
            title="hidden_iframe"
          />
        </SectionWrapper>
      </Section>

      <Footer />
    </Container>
  );
};

export default SupportPage;
