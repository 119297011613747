import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Font1,
  Font2,
  Font3,
  Font4,
  Font5,
  Font6,
  Font7,
  Font8,
  Font9,
  Video,
  VideoRed,
} from "./SplashScreen.styles";
import SplashVideo from "../../assets/videos/splash.mp4";
import SplashVideoRed from "../../assets/videos/gifCrvena.gif";

const SplashScreen = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 2;
    }
  }, []);

  const [fade, setFade] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setFade(true), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container $fade={fade}>
      <Font1>fontPreload</Font1>
      <Font2>fontPreload</Font2>
      <Font3>fontPreload</Font3>
      <Font4>fontPreload</Font4>
      <Font5>fontPreload</Font5>
      <Font6>fontPreload</Font6>
      <Font7>fontPreload</Font7>
      <Font8>fontPreload</Font8>
      <Font9>fontPreload</Font9>
      <Video ref={videoRef} autoPlay muted>
        <source src={SplashVideo} type="video/mp4" />
      </Video>
      <VideoRed src={SplashVideoRed} />
    </Container>
  );
};

export default SplashScreen;
