import React from "react";
import styled, { keyframes } from "styled-components";

const fade = keyframes`
  from {
     opacity: 1; 
    }
  to { 
    opacity: 0.25; 
    }
`;

const Spinner = styled.div`
  position: relative;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const Bar = styled.div`
  width: 0.5rem;
  height: 1.2rem;
  background: #000;
  position: absolute;
  opacity: 0;
  border-radius: 2rem;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  animation: ${fade} 1s linear infinite;

  /* Bar positions */
  &:nth-child(1) {
    transform: rotate(0deg) translate(0, -100%);
    animation-delay: 0s;
  }
  &:nth-child(2) {
    transform: rotate(45deg) translate(0, -100%);
    animation-delay: -0.875s;
  }
  &:nth-child(3) {
    transform: rotate(90deg) translate(0, -100%);
    animation-delay: -0.75s;
  }
  &:nth-child(4) {
    transform: rotate(135deg) translate(0, -100%);
    animation-delay: -0.625s;
  }
  &:nth-child(5) {
    transform: rotate(180deg) translate(0, -100%);
    animation-delay: -0.5s;
  }
  &:nth-child(6) {
    transform: rotate(225deg) translate(0, -100%);
    animation-delay: -0.375s;
  }
  &:nth-child(7) {
    transform: rotate(270deg) translate(0, -100%);
    animation-delay: -0.25s;
  }
  &:nth-child(8) {
    transform: rotate(315deg) translate(0, -100%);
    animation-delay: -0.125s;
  }
`;

const IOSLoader = () => {
  return (
    <Spinner>
      <Bar />
      <Bar />
      <Bar />
      <Bar />
      <Bar />
      <Bar />
      <Bar />
      <Bar />
    </Spinner>
  );
};

export default IOSLoader;
